.data-table::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    position:absolute;
    right:0;
}
  
.data-table::-webkit-scrollbar-thumb {
    background-color: #ddd; /* Set the color of the scrollbar thumb */ 
    border-radius: 5px; /* Set the border radius of the scrollbar thumb */
    height:20px;
  }
  
  .data-table::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background of the scrollbar track to transparent */
   
  }

